<template>
  <el-container 
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-header height="auto">
      <Header :userInfo="userInfo"></Header>
    </el-header>

    <el-main>
      <!--导航-->
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">考试列表</el-breadcrumb-item>
        <el-breadcrumb-item>查看信息</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="box-header">
        <span>考试名称：{{ examName }}</span>
        <span>科目：{{ subjectName }}</span>
        <span>监控组号：{{ groupId }}</span>
      </div>
      <!-- card面板 -->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <!-- 头部标题与form表单 -->
          <el-row :gutter="20">
            <!-- span属性指的是占据多少列 -->
            <el-col :span="6">
              <span>学生信息</span>
            </el-col>
            <!-- 搜索框 -->
            <el-input
              v-model="searchTableInfo"
              placeholder="输入考生姓名"
              prefix-icon="el-icon-search"
              class="myinput"
              clearable
            ></el-input>
            <!--选择框-->
            <el-select v-model="queryInfo.status" placeholder="在线状态" clearable @change="info">
              <el-option
                v-for="item in videoStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-row>
        </div>
        <div class="text item">
          <el-table
            stripe
            height="600"
            :header-cell-style="{
              backgroundColor: '#563279',
              color: '#FFFFFF',
            }"
            :data="
              tableData.slice((queryInfo.pageNum - 1) * queryInfo.pageSize, queryInfo.pageNum * queryInfo.pageSize)
            "
          >
            <el-table-column
              prop="zkzNum"
              label="准考证号"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="schoolName"
              label="中学"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="idCard"
              label="身份证号"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="iphone"
              label="监护人手机号"
              align="center"
              min-width="180"
            >
            </el-table-column>
            <el-table-column
              prop="status"
              label="在线状态"
              align="center"
              min-width="180"
            >
              <!-- <el-tag type="success">a</el-tag>-->
              <template slot-scope="scope">
                <!--0：已结束，1考试中，2待开考-->
                <span v-if="scope.row.status === 0" style="color: #E86B17">离线</span>
                <span v-else-if="scope.row.status === 1" style="color: #442068">在线</span>
                <span v-else-if="scope.row.status === 2" style="color: #4D4D4D">异常</span>
              </template>
            </el-table-column>
            <el-table-column label="身份认证" align="center" min-width="180">
              <template slot-scope="scope">
                <el-link @click="drawerHandle(scope.row.zkzNum)" style="color: #442068"
                  >核实
                </el-link>
                <!--  呼出侧边栏-->
                <el-drawer class="el-drawer__body"
                  title="我是标题"
                  style="overflow: scroll;"
                  :visible.sync="drawer"
                  :with-header="false"


                >
                  <div class="">
                    <h4>1、身份证照片:</h4>
                    <div v-for="(item, index) in sfzImage" :key="index">
                      <img :src="item" alt="无"/>
                    </div>
                    
                  </div>
                  <div>
                    <h4>2、考试机器接口照片:</h4>
                    <div v-for="(item, index) in jkImage" :key="index">
                      <img :src="item" alt="无"/>
                    </div>
                  </div>
                  <div>
                    <h4>3、考试环境照片:</h4>
                    <div v-for="(item, index) in hjImage" :key="index">
                      <img :src="item" alt="无"/>
                    </div>
                  </div>
                </el-drawer>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页器 -->
          <div class="pagination-box">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="queryInfo.pageSize"
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 20, 30, 40]"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.length"
            >
            </el-pagination>
          </div>
        </div>
      </el-card>
    </el-main>
  </el-container>
</template>

<script>
import Header from "c/index/Header";
import { getStudentLists, getDrawerHandle } from "r/index/examstudentinfolist.js";

export default {
  name: "ExamStudentInfoList",
  data() {
    return {
      examName: "",
      subjectName: "",
      groupId: "",
      userInfo: JSON.parse(window.localStorage.getItem("index-userInfo")),
      searchTableInfo: "",
      getSearchInfo: [],
      value: "",
      input: "",
      queryInfo: {
        teacherId: "",
        subjectUuid: "",
        status: "",
        pageNum: 1,
        pageSize: 10
      },
      videoStatus: [
        {
          value: 0,
          label: "离线",
        },
        {
          value: 1,
          label: "在线",
        },
        {
          value: 2,
          label: "异常",
        },
      ],
      drawer: false,
      hjImage: [], // 考试环境照片
      jkImage: [], // 考试机器接口照片
      sfzImage: [], // 身份证照片
      loading:true
    };
  },
  created(){
    this.info()
  },
  methods: {
    // 呼出侧边栏方法
    async drawerHandle(zkzNum){
      this.drawer = true
      // console.log(zkzNum)
      const { data: res } = await getDrawerHandle({ zkz: zkzNum });
      // console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.hjImage = res.data.hjImage.split(",")
      this.jkImage = res.data.jkImage.split(',')
      this.sfzImage = res.data.sfzImage.split(',')
      
    },
    async info() {
      this.queryInfo.teacherId = localStorage.getItem("index-teacherId");
      this.queryInfo.subjectUuid = this.$route.query.subjectUuid;
      // console.log(
        // 111122121,
        // this.queryInfo.teacherId,
        // this.queryInfo.subjectUuid,
      // );
      this.subjectName = this.$route.query.subjectName;
      const { data: res } = await getStudentLists(this.queryInfo);
      if (res.code !== 200) return this.$message.error(res.message);
      // console.log(res,"333333333333333333333");
      this.groupId = res.data.groupId;
      this.examName = res.data.examName;
      this.getSearchInfo = res.data.studentInfoList;
      this.isLoading(res)
    },
    // 控制每页显示几条数据
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.info()
    },
    // 控制显示第几页
    handleCurrentChange(newNum) {
      this.queryInfo.pageNum = newNum;
      this.info();
    },

    //是否显示加载效果
    isLoading(data){
      if (data) {
         this.loading = false
      }else {
        this.loading =true
      }
    }
  },
  computed: {
    created() {
      this.info();
    },
    tableData() {
      const searchTableInfo = this.searchTableInfo;
      if (searchTableInfo) {
        // filter() 方法创建一个新的数组，新数组中的元素是通过检查指定数组中符合条件的所有元素。
        // 注意： filter() 不会对空数组进行检测。
        // 注意： filter() 不会改变原始数组。
        return this.getSearchInfo.filter((data) => {
          // console.log("success" + data);
          // some() 方法用于检测数组中的元素是否满足指定条件;
          // some() 方法会依次执行数组的每个元素：
          // 如果有一个元素满足条件，则表达式返回true , 剩余的元素不会再执行检测;
          // 如果没有满足条件的元素，则返回false。
          // 注意： some() 不会对空数组进行检测。
          // 注意： some() 不会改变原始数组。
          return Object.keys(data).some((key) => {
            // indexOf() 返回某个指定的字符在某个字符串中首次出现的位置，如果没有找到就返回-1；
            // 该方法对大小写敏感！所以之前需要toLowerCase()方法将所有查询到内容变为小写。
            return (
              String(data[key])
                .toLowerCase()
                .indexOf(searchTableInfo) > -1
            );
          });
        });
      }
      return this.getSearchInfo;
    },
  },
  created() {
    this.info();
  },

  components: {
    Header,
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/indexList";
@import "a/scss/table";
@import "a/scss/page";
</style>
